import React, { useEffect } from 'react';
import './Home.css';
import synergyyhome from './synergyyhome.png';
import ptel from './els.svg';
import Testimonials from './Testimonials';
import ldss from './ldss.svg';
import stsm from './stsm.svg';
// import synergyyLogo from './synergyy.png';
// import cardall from './cards_all.svg';
import { Link } from 'react-router-dom';
import plusCourseLogoBg from './PlusCourseBg.png';
import pdeCourseLogoBg from './PDECourseBg.png';
// import synergyyLogoClear from './synergyy_clear.png';



const Home = () => {
  useEffect(() => {
    const counters = document.querySelectorAll('.counter-number');
    const speed = 200; // The lower the slower

    counters.forEach(counter => {
      const updateCount = () => {
        const target = +counter.getAttribute('data-target');
        const count = +counter.innerText;

        const increment = target / speed;

        if (count < target) {
          counter.innerText = Math.ceil(count + increment);
          setTimeout(updateCount, 10);
        } else {
          counter.innerText = target;
        }
      };

      updateCount();
    });
  }, []);


  return (
    <div className="home">
      <h1>Process Design & Advance Software Consultancy</h1>
      <div className="home-content">

        <div className="home-text">
          <b>SYNERGYY is the fastest growing service provider in technical consulting & process analytics across India.</b>
          <p>
            We deliver high end engineering solutions that enables industry players safer, sustainable, cost-effective operations through its integrated solution. We provide best in class services in process engineering solutions, FEED, BEDP, energy optimization & troubleshooting. As service provider we are committed to tackle every challenge from normal process verification to complete design of plant by leveraging our engineering expertise and technologies.
          </p>
        </div>
        <div className="home-image">
          <img src={synergyyhome} alt="Process Design" />
        </div>
      </div>
      
      <div className="home-content">
      <div className="home-video">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/owt1SiAeeAc"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <div className="home-text">
          <p><b>
            Our services are diversified to industrial training programs covering training on troubleshooting & industrial software solution </b>
          </p>
          <p>
            We are consultancy company equipping students with technical skills & practical exposure through internships and online trainings in field of Chemical Engineering. Our training programs in Process Design Engineering, Distillation column design & troubleshooting, Mechanical Design Engineering, Aspen plus, Apsen HYSYS, Aspen EDR, Flarenet & PSV sizing are best suitable who want to achieve milestone in their process engineering career. Engineering institutes looking for us for experts training programs.
          </p>
        </div>

      </div>

      <div className="course-container">
      <h1>Explore Training Programs</h1>
      <h2>Boost your career with industrial training programs</h2>
      <div className="courses">
        <div className="course">
          <Link to="/PDE">
          <div className="image-container">
            <img src={pdeCourseLogoBg} alt="Process Design & Engineering" />
            <div className="overlay">
            <h3>Process Design & Engineering</h3>
            </div>
            </div>
          </Link>
        </div>
        <div className="course">
          <Link to="/AspenPlus">
          <div className="image-container">
            <img src={plusCourseLogoBg} alt="Aspen Plus" />
            <div className="overlay">
            <h3>Aspen Plus</h3>
            </div>
            </div>
          </Link>
        </div>
      </div>
      </div>

      <section className="why-synergyy">
        <h2>Why Synergyy?</h2>
        <div className="feature">
          <div className="feature-icon">
            <img src={ptel} alt="Expert Lectures" />
          </div>
          <div className="feature-content">
            <h3>Live / Scheduled Expert Lectures</h3>
            <p>
              Learning is an important step for achieving dreams in a student’s journey. We encourage the engineers to explore the concept more in a practical way than theoretical. The live lectures help us in learning the needs of the students and motivates the students to be creative and be passionate learners.
            </p>
          </div>
        </div>
        <div className="feature">
          <div className="feature-content">
            <h3>Live Doubt Solving Sessions</h3>
            <p>
              At Synergyy Tech we always encourage students to ask questions. We have created an atmosphere where students don’t hesitate to ask their doubts. We firmly believe in "More you ask, the more you learn."
            </p>
          </div>
          <div className="feature-icon">
            <img src={ldss} alt="Doubt Solving" />
          </div>
        </div>
        <div className="feature">
          <div className="feature-icon">
            <img src={stsm} alt="Study Material" />
          </div>
          <div className="feature-content">
            <h3>Structured And Targeted Study Material</h3>
            <p>
              We have more practical case studies than theoretical ones. Our trainers are highly skilled with 4-10 years of experience in chemical industries.  They believe in sharing their practical experience to our students.
            </p>
          </div>
        </div>
      </section>


      <Testimonials />
      <div className="counters">
        <div className="counter">
          <i className="fas fa-users"></i>
          <div className="counter-number" data-target="780">0</div>
          <div className="counter-title">Students Trained</div>
        </div>
        <div className="counter">
          <i className="fas fa-layer-group"></i>
          <div className="counter-number" data-target="1">0</div>
          <div className="counter-title">Batches Conducted</div>
        </div>
        <div className="counter">
          <i className="fas fa-video"></i>
          <div className="counter-number" data-target="4">0</div>
          <div className="counter-title">Webinars Conducted</div>
        </div>
        <div className="counter">
          <i className="fas fa-chalkboard-teacher"></i>
          <div className="counter-number" data-target="3">0</div>
          <div className="counter-title">Training Programs</div>
        </div>
      </div>

{/*      
      <footer className="footer">
        <div className="footer-container">
          <div className="footer-section">
            <h4>Main menu</h4>
            <p><ul>
              <li>Home</li>
              <li>Courses</li>
              <li>Webinars</li>
              <li>Training</li>
              <li>More</li>
            </ul>
            </p>
          </div>
          <div className="footer-section">
            <h4>Other Resources</h4>
            <p><ul>
              <li>Synergyy Job Postings</li>
              <li>Synergyy Chem-Connect Monthly</li>
              <li>Synergyy Process Safety Monthly</li>
              <li>Synergyy Community</li>
            </ul>
            </p>
          </div>
          <div className="footer-section">
            <h4>Contact</h4>
            <p><ul>
            <li><span>📍</span> Vadodara</li>
            <li><span>✉️</span> info@synergyytech.com</li>
            <li><span>📞</span> +919429992192</li>
            </ul>
            </p>
          </div>
          <div className="footer-section">
            <img src={synergyyLogoClear} alt="Synergyy Logo" className="footer-logo" />
            <p>We are consultancy company equipping students with technical skills & practical exposure through internships and online trainings in field of Chemical Engineering. Thus providing best Interns across the India to Chemical Industry.</p>
            <div className="footer-social">
              <a href="https://www.facebook.com"><i className="fab fa-facebook fa-2x"></i></a>
              <a href="https://www.twitter.com"><i className="fab fa-twitter fa-2x"></i></a>
              <a href="https://www.instagram.com"><i className="fab fa-instagram fa-2x"></i></a>
              <a href="https://www.linkedin.com/company/synergyy-tech"><i className="fab fa-linkedin fa-2x"></i></a>
              <a href="https://www.youtube.com"><i className="fab fa-youtube fa-2x"></i></a>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <p>© 2024 Synergyy Tech</p>
          <div className="footer-payment">
            <img src={cardall} alt="cards" />
          </div>
        </div>
      </footer> */}

    </div>
    
  );
};

export default Home;
