import React, { useState } from 'react';
import './Contact.css';
import { Link } from 'react-router-dom';
import contactpng from './contact.png';
import { ref, set } from "firebase/database";
import { database } from '../firebase';

const ContactUs = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState({});

  const handleInputChange = (setter) => (event) => {
    setter(event.target.value);
  };

  const validateForm = () => {
    const newErrors = {};
    const phonePattern = /^[0-9]{10}$/;
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (!firstName.trim()) newErrors.firstName = 'First name is required';
    if (!lastName.trim()) newErrors.lastName = 'Last name is required';
    if (!phonePattern.test(phone)) newErrors.phone = 'Phone number must be 10 digits';
    if (!emailPattern.test(email)) newErrors.email = 'Invalid email address';
    if (!message.trim()) newErrors.message = 'Message is required';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSaveFeedback = (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    const feedbackData = {
      firstName,
      lastName,
      phone,
      email,
      message
    };

    // Save feedback to Firebase
    set(ref(database, 'feedback/' + new Date().getTime()), feedbackData)
      .then(() => {
        console.log('Feedback saved to Firebase successfully');
      })
      .catch((error) => {
        console.error("Error saving feedback to Firebase:", error);
      });

    // Save feedback to localStorage
    localStorage.setItem('feedback', JSON.stringify(feedbackData));

    // Clear the form fields
    setFirstName('');
    setLastName('');
    setPhone('');
    setEmail('');
    setMessage('');
    setErrors({});
  };

  return (
    <div className="contact-us-container">
      <h1>Contact Us</h1>
      <div className="contact-info">
        <img src={contactpng} alt="Contact" className="contact-image" />
        <div className="contact-details">
          <h2>We're here to answer your questions</h2>
          <p>Working 9am - 5pm Mon/Friday.</p>
          <p>We do our best to answer all questions within a timely manner.</p>
          <p>If you have a question please complete the form below and we'll get back to you ASAP.</p>
          <p>Office address: Vadodara </p>
          <p>+91 9429992192 / info@synergyytech.com</p>
        </div>
      </div>
      <div className="contact-form">
        <h2>Get In Touch</h2>
        <p>Complete the form below and we'll get back to you within 48 hours.</p>
        <form onSubmit={handleSaveFeedback}>
          <div className="form-group">
            <input 
              type="text" 
              placeholder="First Name" 
              value={firstName} 
              onChange={handleInputChange(setFirstName)} 
              required 
            />
            {errors.firstName && <span className="error">{errors.firstName}</span>}
                      
            <input 
              type="text" 
              placeholder="Last Name" 
              value={lastName} 
              onChange={handleInputChange(setLastName)} 
              required 
            />
            {errors.lastName && <span className="error">{errors.lastName}</span>}
          </div>
          <div className="form-group">
            <input 
              type="text" 
              placeholder="Phone" 
              value={phone} 
              onChange={handleInputChange(setPhone)} 
              required 
            />
            {errors.phone && <span className="error">{errors.phone}</span>}

            <input 
              type="email" 
              placeholder="Email" 
              value={email} 
              onChange={handleInputChange(setEmail)} 
              required 
            />
            {errors.email && <span className="error">{errors.email}</span>}

          </div>
          <textarea 
            placeholder="Message" 
            value={message} 
            onChange={handleInputChange(setMessage)} 
            required 
          ></textarea>
          {errors.message && <span className="error">{errors.message}</span>}

          <div className="form-group">
            <input type="checkbox" id="terms" required />
            <label htmlFor="terms">I agree to the <Link to="/terms">Terms</Link> and <Link to="/privacy">Privacy</Link>.</label>
          </div>
        
          <button type="submit">Send Message</button>
        </form>
      </div>
    </div>
  );
}

export default ContactUs;
