import React, { useState } from 'react';
import './coursepg.css';
import { Link } from 'react-router-dom';
import plusCourseLogoBg from './PlusCourseBg.png';
/* import pdeCourseLogoBg from './PDECourseBg.png';
 */import Testimonials from './Testimonials';
import pdeCourseContent from './ST_PDEC_Single-Page.png';
import { database } from '../firebase'; // Import the Firebase database
import { ref, set } from 'firebase/database'; // Import Firebase database functions

const PDE = () => {

  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    education: 'B.Tech',
  });
  const [errors, setErrors] = useState({
    name: '',
    phone: '',
    email: '',
  });
  const validate = () => {
    let formValid = true;
    let errors = {};

    if (!formData.name) {
      formValid = false;
      errors.name = 'Name is required';
    }

    const phonePattern = /^[0-9]{10}$/;
    if (!formData.phone || !phonePattern.test(formData.phone)) {
      formValid = false;
      errors.phone = 'Valid phone number is required';
    }

    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!formData.email || !emailPattern.test(formData.email)) {
      formValid = false;
      errors.email = 'Valid email is required';
    }

    setErrors(errors);
    return formValid;
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    if (validate()) {
      const newEnrollmentRef = ref(database, 'enrollments/Aspen Plus/' + Date.now());
      set(newEnrollmentRef, formData)
        .then(() => {
          alert('Enrollment successful!');
          setFormData({
            name: '',
            phone: '',
            email: '',
            education: 'B.Tech',
          });
        })
        .catch((error) => {
          console.error('Error enrolling:', error);
        });
    }
  };


  return (
    <div>
      <div className="course_title">
      <h1>Process Design & Engineering</h1>
      </div>

      <div className="course-content-container">
      <p>
        Process Design & Engineering course will improve your skills which are the most demanding in Chemical and Petrochemical Industries.
        This course focuses on understanding the important technical fundamentals of chemical process design. 
      </p>

      <div className="enrollment-section">
        <div className="enrollment-form">
          <h2>Enroll for Process Design & Engineering</h2>
          <form onSubmit={handleSubmit}>
              <label>Name (Required)</label>
              <input type="text" name="name" value={formData.name} onChange={handleChange} required />
              {errors.name && <span className="error">{errors.name}</span>}

              <label>Phone (Required)</label>
              <input type="text" name="phone" value={formData.phone} onChange={handleChange} required />
              {errors.phone && <span className="error">{errors.phone}</span>}

              <label>Email (Required)</label>
              <input type="email" name="email" value={formData.email} onChange={handleChange} required /> 
              {errors.email && <span className="error">{errors.email}</span>}
              
              <label>Highest Education (Required)</label>
              <select name="education" value={formData.education} onChange={handleChange} required>
                <option value="B.Tech">B.Tech</option>
                <option value="M.Tech">M.Tech</option>
                <option value="PhD">PhD</option>
              </select>
              
              <button type="submit">Enroll Now</button>
            </form>
        </div>
        <div className="image2-container">
          <img src={pdeCourseContent} alt="Learn Process Design & Engineering" />
        </div>
      </div>

      <div className="content-section">
          <h2>Course Content</h2>
          <ul>
            <li>Role of process engineer in chemical industry</li>
            <li>Types of projects - Design basis - Project economics</li>
            <li>Introduction to BEP - BFD PFD</li>
            <li>P&ID - Understanding and preparation</li>
            <li>Relevant codes and standards used in industry: TEMA, ANSI, IBR, ASME, IS GPSA</li>
            <li>Line Sizing: Hydraulics and Pressure Drop Calculations</li>
            <li>Types of valves and applications</li>
            <li>Control Valve: Sizing and Rating</li>
            <li>Steam Traps: Types and selection</li>
            <li>Instruments Process datasheets: Flow/Level/temperature/Pressure</li>
            <li>Pumps: Classification and pump curves</li>
            <li>Pumps: Sizing and hydraulics</li>
            <li>Fans & Blowers</li>
            <li>Compressors</li>
            <li>Cooling Tower Concepts and Sizing</li>
            <li>Storage Tanks: Classification, selection, Design and Datasheets</li>
            <li>Heat Exchangers: Classification and Selection</li>
            <li>Heat Exchangers Design: Sizing and Rating</li>
            <li>Utility: Selection and Sizing</li>
            <li>Steam ejectors and Vacuum pumps</li>
            <li>Distillation Column: Types and Selection</li>
            <li>Distillation Column: Design</li>
            <li>Agitator: Selection & design</li>
            <li>Boiler: Performance and efficiency calculations</li>
            <li>VL separator: Flash vessel and Knock out pot</li>
            <li>LL separator: Decanter</li>
            <li>PDS (Process datasheet) of Major Equipments</li>
            <li>HAC (Hazardous Area Classification)</li>
            <li>Introduction to HAZOP: Case Studies</li>
            <li>PSV and PRV: Selection and Sizing by API 520 and API 521</li>
            <li>Breather valve (PVRV): API 2000 and vent sizing</li>
            <li>Types of control philosophy and automation</li>
            <li>Effluent characterization and treatment methods</li>
            <li>Simulation software Applications in Process Engineering</li>
            <li>Interview Guidance</li>
          </ul>
        </div>

        <div className="content-section">
          <h2>What will you learn?</h2>
          <ul>
            <li>Role of process engineers in the chemical industry.</li>
            <li>Various project types, their design fundamentals, and economic considerations.</li>
            <li>Break Even Point (BEP) analysis and understanding Basic Engineering Package (BEP), Block Flow Diagram (BFD), and Process Flow Diagram (PFD).</li>
            <li>Reading, interpreting, and creating Piping and Instrumentation Diagrams (P&ID).</li>
            <li>Familiarity with industry codes and standards like TEMA, ANSI, IBR, ASME, IS, and GPSA.</li>
            <li>Line sizing techniques, including hydraulics and pressure drop calculations.</li>
            <li>Types of valves and their applications in industrial processes.</li>
            <li>Sizing and rating of control valves.</li>
            <li>Selection criteria for steam traps.</li>
            <li>Preparation of instrument process datasheets for flow, level, temperature, and pressure.</li>
            <li>Understanding pump classifications and pump curves.</li>
            <li>Sizing pumps and understanding hydraulics.</li>
            <li>Basic knowledge of fans, blowers, and compressors.</li>
            <li>Concepts and sizing considerations for cooling towers.</li>
            <li>Understanding storage tank classifications, selection, design, and datasheet preparation.</li>
            <li>Classification, selection, and sizing of heat exchangers.</li>
            <li>Heat exchanger design principles and rating methods.</li>
            <li>Selection and sizing of utility systems.</li>
            <li>Introduction to steam ejectors and vacuum pumps.</li>
            <li>Distillation column types, selection criteria, and design principles.</li>
            <li>Agitator selection and basic design concepts.</li>
            <li>Boiler performance and efficiency calculations.</li>
            <li>Understanding Vapor-Liquid (VL) separators and Liquid-Liquid (LL) separators.</li>
            <li>Preparation of Process datasheets (PDS) for major equipment.</li>
            <li>Introduction to Hazardous Area Classification (HAC).</li>
            <li>Hazard and Operability Study (HAZOP) with case studies.</li>
            <li>Selection and sizing of Pressure Safety Valves (PSV) and Pressure Relief Valves (PRV) using API standards.</li>
            <li>Breather valve (PVRV) principles and vent sizing according to API standards.</li>
            <li>Different control philosophies and automation techniques.</li>
            <li>Effluent characterization and treatment methods.</li>
            <li>Applications of simulation software in process engineering.</li>
            <li>Interview guidance focusing on key success factors.</li>
          </ul>
        </div>
    </div>

      <div className="course-container">
      <h1>Explore Training Programs</h1>
      <h2>Boost your career with industrial training programs</h2>
      <div className="courses">
 {/*        <div className="course">
          <Link to="/PDE">
          <div className="image-container">
            <img src={pdeCourseLogoBg} alt="Process Design & Engineering" />
            <div className="overlay">
            <h3>Process Design & Engineering</h3>
            </div>
            </div>
          </Link>
        </div> */}
        <div className="course">
          <Link to="/AspenPlus">
          <div className="image-container">
            <img src={plusCourseLogoBg} alt="Aspen Plus" />
            <div className="overlay">
            <h3>Aspen Plus</h3>
            </div>
            </div>
          </Link>
        </div>
      </div>
      </div>
      <Testimonials />

    </div>
  );
};

export default PDE;