import React, { useState } from 'react';
import './Register.css';
import { Link, useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase';

const Register = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [captcha, setCaptcha] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const validateForm = () => {
    const errors = {};
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailPattern.test(email)) {
      errors.email = 'Please enter a valid email address';
    }

    if (password.length < 6) {
      errors.password = 'Password must be at least 6 characters long';
    }

    if (captcha !== '7') {
      errors.captcha = 'Incorrect captcha answer';
    }

    if (!termsAccepted) {
      errors.terms = 'You must accept the terms and conditions';
    }

    setErrors(errors);

    // Returns true if there are no errors
    return Object.keys(errors).length === 0;
  };
  
  const handleRegister  = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return; // Stop the submission if validation fails
    }

    try {
      await createUserWithEmailAndPassword(auth, email, password);
      alert('Registration successful!');
      navigate('/'); // Navigate to home or another page after successful registration
    } catch (error) {
      alert('Error registering: ' + error.message);
    }
  };

  return (
    <div className="register-container">
    <h2>Register</h2>
    <form onSubmit={handleRegister}>
      <div className="form-group">
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        {errors.email && <span className="error-text">{errors.email}</span>}
      </div>
      <div className="form-group">
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        {errors.password && <span className="error-text">{errors.password}</span>}
      </div>
      <div className="form-group">
        <input
          type="text"
          placeholder="What is: 7 + 0 ="
          value={captcha}
          onChange={(e) => setCaptcha(e.target.value)}
          required
        />
        {errors.captcha && <span className="error-text">{errors.captcha}</span>}
      </div>
      <div className="form-group terms">
        <label>
          I agree to the <Link to="/terms">Terms</Link> and <Link to="/privacy">Privacy</Link>.
        </label>
        <input
          type="checkbox"
          checked={termsAccepted}
          onChange={() => setTermsAccepted(!termsAccepted)}
          required
        />
        {errors.terms && <span className="error-text">{errors.terms}</span>}
      </div>
      <button type="submit" className="register-button">Register</button>
    </form>
    <div className="signin-link">
      Already a member? <Link to="/signInPage">Sign In</Link>
    </div>
  </div>
  );
};

export default Register;
