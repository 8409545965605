// CourseDetail.js
import React from 'react';
import { useParams } from 'react-router-dom';
import './CourseDetail.css';
import plusCourseLogoIcon from './pluscourse.jpg';
import pdeCourseLogoIcon from './pdecourse.jpg';
import hysysCourseLogoIcon from './hysyscourse.jpeg';
const courses = [
  {
    id: 'ProcessDesignEngieering',
    title: 'Process Design & Engineering (June 2024)',
    instructor: 'Synergyy',
    duration: 60,
    originalPrice: 41299,
    discountedPrice: 20059,
    bookedPercentage: 7,
    usersCount: 12,
    image: hysysCourseLogoIcon, // Image path or URL
    overview: 'Join this program to improve the skills needed for a chemical engineer to transform into a good process engineer.',
    syllabus: [
      'Introduction to Process Design',
      'P&ID Development',
      'Equipment Sizing and Specification',
      'Process Simulation',
      'Safety in Process Design',
    ],
    instructorBio: 'Synergyy is a team of experienced professionals with a strong background in process engineering and design.',
    reviews: [
      { user: 'John Doe', comment: 'Great course! Very informative.', rating: 5 },
      { user: 'Jane Smith', comment: 'Learned a lot about process design.', rating: 4 },
    ],
    videoUrl: 'https://www.youtube.com/embed/owt1SiAeeAc', // URL to the course video preview
    materialIncludes: [
      'Documented notes',
      'Live lectures',
      'Recorded Sessions',
      'Weekly Test'
    ],
    requirements: [
      'Laptop/Mobile to access the live/recorded lectures.'
    ],
    audience: [
      'BE/ B Tech in Chemical, Petrochemical Engineering',
      'ME/ M Tech in Chemical Petrochemical Engineering',
      'Engineers working in Production and other fields',
      'Last year Students in Chemical, Petrochemical Engineering',
      'Diploma in Chemical, Petrochemical Engineering'
    ]
  },
  {
    id: 'AspenPlus',
    title: 'Aspen Plus (June 2024)',
    instructor: 'Synergyy',
    duration: 60,
    originalPrice: 41299,
    discountedPrice: 20059,
    bookedPercentage: 30,
    usersCount: 38,
    image: plusCourseLogoIcon, // Image path or URL
    overview: 'Join this program to improve the skills needed for a chemical engineer to transform into a good process engineer.',
    syllabus: [
      'Introduction to Process Design',
      'P&ID Development',
      'Equipment Sizing and Specification',
      'Process Simulation',
      'Safety in Process Design',
    ],
    instructorBio: 'Synergyy is a team of experienced professionals with a strong background in process engineering and design.',
    reviews: [
      { user: 'John Doe', comment: 'Great course! Very informative.', rating: 5 },
      { user: 'Jane Smith', comment: 'Learned a lot about process design.', rating: 4 },
    ],
    videoUrl: 'https://www.youtube.com/embed/owt1SiAeeAc', // URL to the course video preview
    materialIncludes: [
      'Documented notes',
      'Live lectures',
      'Recorded Sessions',
      'Weekly Test'
    ],
    requirements: [
      'Laptop/Mobile to access the live/recorded lectures.'
    ],
    audience: [
      'BE/ B Tech in Chemical, Petrochemical Engineering',
      'ME/ M Tech in Chemical Petrochemical Engineering',
      'Engineers working in Production and other fields',
      'Last year Students in Chemical, Petrochemical Engineering',
      'Diploma in Chemical, Petrochemical Engineering'
    ]
  },
  {
    id: 'AspenHysys',
    title: 'Aspen Hysys (June 2024)',
    instructor: 'Synergyy',
    duration: 38,
    originalPrice: 41299,
    discountedPrice: 16050,
    bookedPercentage: 90,
    usersCount: 50,
    image: pdeCourseLogoIcon, // Image path or URL
    overview: 'Join this program to improve the skills needed for a chemical engineer to transform into a good process engineer.',
    syllabus: [
      'Introduction to Process Design',
      'P&ID Development',
      'Equipment Sizing and Specification',
      'Process Simulation',
      'Safety in Process Design',
    ],
    instructorBio: 'Synergyy is a team of experienced professionals with a strong background in process engineering and design.',
    reviews: [
      { user: 'John Doe', comment: 'Great course! Very informative.', rating: 5 },
      { user: 'Jane Smith', comment: 'Learned a lot about process design.', rating: 4 },
    ],
    videoUrl: 'https://www.youtube.com/embed/owt1SiAeeAc', // URL to the course video preview
    materialIncludes: [
      'Documented notes',
      'Live lectures',
      'Recorded Sessions',
      'Weekly Test'
    ],
    requirements: [
      'Laptop/Mobile to access the live/recorded lectures.'
    ],
    audience: [
      'BE/ B Tech in Chemical, Petrochemical Engineering',
      'ME/ M Tech in Chemical Petrochemical Engineering',
      'Engineers working in Production and other fields',
      'Last year Students in Chemical, Petrochemical Engineering',
      'Diploma in Chemical, Petrochemical Engineering'
    ]
  },
  // Add more courses as needed
];

const CourseDetail = () => {
  const { courseId } = useParams();
  const course = courses.find(course => course.id === courseId);

  if (!course) {
    return <div>Course not found</div>;
  }

  return (
    <div className="course-detail">
    <h1>{course.title}</h1>
    <img src={course.image} alt={course.title} />
    <p><strong>Instructor:</strong> {course.instructor}</p>
    <p><strong>Duration:</strong> {course.duration}h</p>
    <p><strong>Original Price:</strong> ₹{course.originalPrice}</p>
    <p><strong>Discounted Price:</strong> ₹{course.discountedPrice}</p>
    <p><strong>Enrolled:</strong> {course.usersCount} users</p>
    <p><strong>Booked:</strong> {course.bookedPercentage}%</p>

    <div className="course-video">
      <iframe
        width="560"
        height="315"
        src={course.videoUrl}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Course video"
      ></iframe>
    </div>

    <h2>About Course</h2>
    <p>{course.overview}</p>

    <h2>Course Syllabus</h2>
    <ul>
      {course.syllabus.map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </ul>

    <h2>Instructor Bio</h2>
    <p>{course.instructorBio}</p>

    <h2>Reviews</h2>
    <ul>
      {course.reviews.map((review, index) => (
        <li key={index}>
          <strong>{review.user}:</strong> {review.comment} ({review.rating} stars)
        </li>
      ))}
    </ul>

    {/* Add more course details as needed */}
  </div>
  );
};

export default CourseDetail;
