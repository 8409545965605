import React from 'react';
import 'swiper/swiper-bundle.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import './Testimonials.css';
import testimonialLogo from './synergyy.png'; // Adjust the path to your logo file

const Testimonials = () => {
  const testimonials = [
  
    {
      name: 'Aarav Patel',
      title: 'Process Design Engineer',
      feedback: 'The training was highly practical and greatly improved my design skills.',
      stars: 5,
    },
    {
      name: 'Riya Mehta',
      title: 'Aspen Plus Trainee',
      feedback: 'Aspen Plus training was excellent. The simulations were very realistic.',
      stars: 5,
    },
    {
      name: 'Aditya Sharma',
      title: 'Aspen Plus Trainee',
      feedback: 'Fantastic Aspen Plus course. Learned a lot!',
      stars: 5,
    },
    {
      name: 'Ishita Gupta',
      title: 'Aspen Plus Trainee',
      feedback: 'The Aspen Plus training was very detailed and easy to follow.',
      stars: 5,
    },
    {
      name: 'Arjun Kumar',
      title: 'Process Design Engineer',
      feedback: 'The real-world examples were extremely helpful. Great course!',
      stars: 5,
    },
    {
      name: 'Nidhi Singh',
      title: 'Aspen Plus Trainee',
      feedback: 'Very informative training on Aspen Plus. Highly recommend it.',
      stars: 5,
    },
    {
      name: 'Vihaan Reddy',
      title: 'Aspen Plus Trainee',
      feedback: 'The hands-on approach of the Aspen Plus course made it very engaging.',
      stars: 5,
    },
    {
      name: 'Aisha Rao',
      title: 'Process Design Engineer',
      feedback: 'The instructors were very knowledgeable and helpful.',
      stars: 5,
    },
    {
      name: 'Kabir Desai',
      title: 'Aspen Plus Trainee',
      feedback: 'The Aspen Plus training was comprehensive and well-structured.',
      stars: 5,
    },
    {
      name: 'Tara Bhatt',
      title: 'Process Design Engineer',
      feedback: 'A well-structured course with a clear focus on practical applications.',
      stars: 5,
    },
    {
      name: 'Dev Mishra',
      title: 'Aspen Plus Trainee',
      feedback: 'Great balance of theory and practice in the Aspen Plus course.',
      stars: 5,
    },
    {
      name: 'Anika Jain',
      title: 'Aspen Plus Trainee',
      feedback: 'Thoroughly enjoyed the Aspen Plus training. Very informative.',
      stars: 5,
    },
    {
      name: 'Arnav Joshi',
      title: 'Aspen Plus Trainee',
      feedback: 'The practical exercises in the Aspen Plus course were very useful.',
      stars: 5,
    },
    {
      name: 'Simran Kaur',
      title: 'Process Design Engineer',
      feedback: 'Highly recommend this Process Design Engineering course.',
      stars: 5,
    },
    {
      name: 'Rohan Chopra',
      title: 'Aspen Plus Trainee',
      feedback: 'The Aspen Plus course was well-organized and very useful.',
      stars: 5,
    },
    {
      name: 'Ananya Nair',
      title: 'Process Design Engineer',
      feedback: 'The training was very detailed and thorough. Great experience!',
      stars: 5,
    },
    {
      name: 'Yash Goel',
      title: 'Aspen Plus Trainee',
      feedback: 'The Aspen Plus training exceeded my expectations. Very thorough.',
      stars: 5,
    },
    {
      name: 'Neha Verma',
      title: 'Process Design Engineer',
      feedback: 'The Process Design Engineering course was incredibly helpful.',
      stars: 5,
    },
    {
      name: 'Sophie Martin',
      title: 'Process Design Engineer',
      feedback: 'The Process Design Engineering course was incredibly detailed and thorough.',
      stars: 5,
    },
    {
      name: 'Ella Scott',
      title: 'Aspen Plus Trainee',
      feedback: 'Aspen Plus training exceeded my expectations. Very thorough and detailed.',
      stars: 5,
    },
    {
      name: 'James White',
      title: 'Aspen Plus Trainee',
      feedback: 'Gained a deep understanding of Aspen Plus. Great course!',
      stars: 5,
    },
    {
      name: 'Amelia Harris',
      title: 'Aspen Plus Trainee',
      feedback: 'The hands-on approach made learning Aspen Plus very engaging.',
      stars: 5,
    },
    {
      name: 'Michael Phillips',
      title: 'Aspen Plus Trainee',
      feedback: 'The instructor made complex topics easy to understand.',
      stars: 5,
    },
    {
      name: 'Jacob Lee',
      title: 'Process Design Engineer',
      feedback: 'Excellent training! The real-world examples were extremely helpful.',
      stars: 5,
    },
    {
      name: 'Emma Davis',
      title: 'Process Design Engineer',
      feedback: 'A well-structured course with a clear focus on practical applications.',
      stars: 5,
    },
    {
      name: 'Liam Taylor',
      title: 'Process Design Engineer',
      feedback: 'Highly recommend this training to anyone looking to advance their process design skills.',
      stars: 5,
    },
    {
      name: 'Olivia Brown',
      title: 'Process Design Engineer',
      feedback: 'The instructors were knowledgeable and always available for questions.',
      stars: 5,
    },
    {
      name: 'Noah Wilson',
      title: 'Process Design Engineer',
      feedback: 'Great balance of theory and practice. Very satisfied with the course.',
      stars: 5,
    },
    {
      name: 'Ava Moore',
      title: 'Process Design Engineer',
      feedback: 'The best course I\'ve taken on process design engineering. Learned so much!',
      stars: 5,
    },
    {
      name: 'Ethan Anderson',
      title: 'Process Design Engineer',
      feedback: 'Thoroughly enjoyed the interactive sessions and case studies.',
      stars: 5,
    },
    {
      name: 'Isabella Thomas',
      title: 'Process Design Engineer',
      feedback: 'This training has significantly improved my understanding of process design.',
      stars: 5,
    },
    {
      name: 'Mason Jackson',
      title: 'Process Design Engineer',
      feedback: 'Very informative and well-paced. Worth every penny.',
      stars: 5,
    },
    {
      name: 'Mia Lewis',
      title: 'Aspen Plus Trainee',
      feedback: 'The best training I\'ve had on Aspen Plus. Highly recommend it.',
      stars: 5,
    },
    {
      name: 'Lucas Robinson',
      title: 'Aspen Plus Trainee',
      feedback: 'The practical examples were very useful in understanding the concepts.',
      stars: 5,
    },
 
    {
      name: 'Abigail Campbell',
      title: 'Aspen Plus Trainee',
      feedback: 'Great course for anyone looking to master Aspen Plus.',
      stars: 5,
    },
    {
      name: 'Daniel Mitchell',
      title: 'Aspen Plus Trainee',
      feedback: 'Very comprehensive training. Covered all the essential topics.',
      stars: 5,
    },
    {
      name: 'Avery Roberts',
      title: 'Aspen Plus Trainee',
      feedback: 'The interactive sessions were very helpful in understanding the concepts.',
      stars: 5,
    },
  ];
  

  return (
    <div className="testimonials">
      <h2>What Students are saying about us!!</h2>
      <Swiper
        modules={[Pagination]}
        slidesPerView={1}
        spaceBetween={10}
        pagination={{ clickable: true }}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
        }}
      >
        {testimonials.map((testimonial, index) => (
          <SwiperSlide key={index}>
            <div className="testimonial-card">
              <div className="testimonial-icon">
              <img src={testimonialLogo} alt="Logo" className="testimonial-logo" />
              </div>
              <div className="testimonial-feedback">"{testimonial.feedback}"</div>
              <div className="testimonial-stars">
                {'★'.repeat(testimonial.stars)}
              </div>
              <div className="testimonial-name">{testimonial.name}</div>
              <div className="testimonial-title">{testimonial.title}</div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Testimonials;
