import React from 'react';
import { Link } from 'react-router-dom';
import './About.css';
import about1 from './about1.png';
import about2 from './about2.png';

const AboutUs = () => {
  return (
    <div className="about-us-container">
      <div className="about-us-header">
        <h1>About Us</h1>
      </div>
      <div className="about-us-content">
        <img src={about1} alt="Team" className="about-us-image" />
        <div className="about-us-text">
          <h4>IN BUSINESS SINCE 2024</h4>
          <p>We've been working hard helping students & hiring managers for over since 3 month.</p>
        </div>
      </div>
      <div className="network-section">
        <div className="network-text">
          <h4>Huge network all over INDIA</h4>
          <p>Students from IIT, NIT, State govt institutions, pvt institutions.</p>
          <p>We are pioneer in providing best internships in chemical engineering domain in all types of industries in chemical engineering world.</p>
          <p>Developing huge hiring managers's network & helping them out to get best interns from top institutions.</p>
          <Link to="/contact" className="contact-button">Contact Us</Link>
        </div>
        <img src={about2} alt="Team" className="network-image" />
      </div>
    </div>
  );
}

export default AboutUs;
