import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';  // Import the ScrollToTop component
import './App.css';
import PageTitle from './PageTitle';
import Home from './components/Home';
import Courses from './components/Courses';
import MyCourses from './components/MyCourses';
import SynergyyCommunity from './components/SynergyyCommunity';
import SynergyyJobPostings from './components/SynergyyJobPosting';
import SynergyyChemConnect from './components/SynergyyChemConnect';
import SynergyyStudentFeedbackForm from './components/SynergyyStudentFeedbackForm';
import Webinars from './components/Webinars';
import Navbar from './components/Navbar';
import BottomNav from './components/BottomNav';
import About from './components/About'; 
import Contact from './components/Contact'; 
import Terms from './components/Terms';
import Privacy from './components/Privacy';
import PDECourse from './components/PDE';
import AspenPlusCourse from './components/AspenPlus';
import SignInPage from './components/SignInPage';
import Register from './components/Register';
import MyAccount from './components/MyAccount';
import CourseDetail from './components/CourseDetail';
import PaymentSuccess from './components/service_sites/PaymentSuccess';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <div className="content">
        <ScrollToTop />
          <Routes>
            <Route path="/" element={<><PageTitle title="Home | SynergyyTech" /><Home /></>} />
            <Route path="/courses" element={<><PageTitle title="Courses | SynergyyTech" /><Courses /></>} />
            <Route path="/my-courses" element={<><PageTitle title="My Courses | SynergyyTech" /><MyCourses /></>} />
            <Route path="/synergyy-community" element={<><PageTitle title="Synergyy Community | SynergyyTech" /><SynergyyCommunity /></>} />
            <Route path="/synergyy-jobpostings" element={<><PageTitle title="Synergyy Job Postings | SynergyyTech" /><SynergyyJobPostings /></>} />
            <Route path="/synergyy-chemconnect" element={<><PageTitle title="Synergyy ChemConnect | SynergyyTech" /><SynergyyChemConnect /></>} />
            <Route path="/synergyy-studentfeedback" element={<><PageTitle title="Student Feedback | SynergyyTech" /><SynergyyStudentFeedbackForm /></>} />
            <Route path="/about" element={<><PageTitle title="About Us | SynergyyTech" /><About /></>} /> 
            <Route path="/contact" element={<><PageTitle title="Contact Us | SynergyyTech" /><Contact /></>} /> 
            <Route path="/terms" element={<><PageTitle title="Terms of Service | SynergyyTech" /><Terms /></>} /> 
            <Route path="/privacy" element={<><PageTitle title="Privacy Policy | SynergyyTech" /><Privacy /></>} /> 
            <Route path="/PDE" element={<><PageTitle title="PDE Course | SynergyyTech" /><PDECourse /></>} /> 
            <Route path="/AspenPlus" element={<><PageTitle title="Aspen Plus Course | SynergyyTech" /><AspenPlusCourse /></>} />
            <Route path="/SignInPage" element={<><PageTitle title="Sign In | SynergyyTech" /><SignInPage /></>} />
            <Route path="/register" element={<><PageTitle title="Register | SynergyyTech" /><Register /></>} />
            <Route path="/Account" element={<><PageTitle title="My Account | SynergyyTech" /><MyAccount /></>} />
            <Route path="/course/:courseId" element={<><PageTitle title="Course Details | SynergyyTech" /><CourseDetail /></>} />
            <Route path="/payment-success" element={<><PageTitle title="Payment Successful | SynergyyTech" /><PaymentSuccess /></>} />

            {/* <Route path="/webinars" element={<Webinars />} /> */} 
            <Route path="/webinars" element={<><PageTitle title="Free Masterclasses | SynergyyTech" /><Webinars /></>} />
            
          </Routes>
        </div>
        <BottomNav />
      </div>
    </Router>
  );
}

export default App;
