import React, { useState } from 'react';
import './SignInPage.css';
import synergyyLogo from './synergyy_clear.png';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase';
import { Link, useNavigate } from 'react-router-dom';

const SignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const validateForm = () => {
    const errors = {};
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailPattern.test(email)) {
      errors.email = 'Please enter a valid email address';
    }

    if (password.length < 6) {
      errors.password = 'Password must be at least 6 characters long';
    }

    setErrors(errors);

    // Returns true if there are no errors
    return Object.keys(errors).length === 0;
  };

  const handleSignIn = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return; // Stop the submission if validation fails
    }
    try {
      await signInWithEmailAndPassword(auth, email, password);
      alert('Sign in successful!');
      navigate('/'); // Navigate to home or another page after successful sign in
    } catch (error) {
      alert('Error signing in: ' + error.message);
    }
  };

  return (
    <div className="signin-container">
{/*     <div className="signin-logo">
      <img src={synergyyLogo} alt="Synergyy Logo" />
    </div> */}
    <h2>Sign In</h2>
    <form onSubmit={handleSignIn}>
      <div className="form-group">
        <label>Email</label>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        {errors.email && <span className="error-text">{errors.email}</span>}
      </div>
      <div className="form-group">
        <label>Password</label>
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        {errors.password && <span className="error-text">{errors.password}</span>}
      </div>
      <div className="form-group">
        <div className="remember-container">
          <label htmlFor="remember">Remember</label>
          <input type="checkbox" id="remember" name="remember"/>
        </div>
        <Link to="/forgot-password" className="forgot-password">
          Lost password?
        </Link>
      </div>
      <button type="submit" className="signin-button">Sign In</button>
    </form>
    <div className="social-login">
      <button className="facebook">Facebook</button>
      <button className="linkedin">LinkedIn</button>
    </div>
    <div className="signup-link">
      Don't have an account? <Link to="/register">Sign Up</Link>
    </div>
  </div>
  );
};

export default SignIn;
