import React, { useEffect } from 'react';

const RazorpayPaymentButton2 = ({ buttonId }) => {
  useEffect(() => {
    if (!document.getElementById(`razorpay-script-${buttonId}`)) {
      const form = document.createElement('form');

      const script = document.createElement('script');
      script.src = 'https://checkout.razorpay.com/v1/payment-button.js';
      script.setAttribute('data-payment_button_id', buttonId);
      script.async = true;
      script.id = `razorpay-script-${buttonId}`; // Add a unique id for each button

      form.appendChild(script);

      document.getElementById(`razorpay-button-container-${buttonId}`).appendChild(form);
    }
  }, [buttonId]);

  return (
    <div id={`razorpay-button-container-${buttonId}`}></div>
  );
};

export default RazorpayPaymentButton2;
