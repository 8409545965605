import React , { useState }from 'react';
import './SynergyyStudentFeedbackForm.css';
import { database } from '../firebase'; // Adjust the path to your firebase configuration file
import { ref, push } from 'firebase/database'; // Import the necessary Firebase database functions

const SynergyyStudentFeedbackForm = () => {

  const [feedback, setFeedback] = useState({
    fullName: '',
    contactNumber: '',
    emailAddress: '',
    interaction: '',
    contentRelevance: '',
    clarityOfPresentation: '',
    engagement: '',
    pace: '',
    useOfExamples: '',
    knowledgeTransfer: '',
    additionalComments: ''
  });

  const [errors, setErrors] = useState({});
  const validate = () => {
    let errors = {};

    if (!feedback.fullName.trim()) {
      errors.fullName = 'Full Name is required';
    }

    const phonePattern = /^[0-9]{10}$/;
    if (!feedback.contactNumber) {
      errors.contactNumber = 'Contact Number is required';
    } else if (!phonePattern.test(feedback.contactNumber)) {
      errors.contactNumber = 'Contact Number must be 10 digits';
    }

    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!feedback.emailAddress) {
      errors.emailAddress = 'Email Address is required';
    } else if (!emailPattern.test(feedback.emailAddress)) {
      errors.emailAddress = 'Email Address is invalid';
    }

    if (!feedback.interaction) {
      errors.interaction = 'Please rate Interaction';
    }

    if (!feedback.contentRelevance) {
      errors.contentRelevance = 'Please rate Content Relevance';
    }

    if (!feedback.clarityOfPresentation) {
      errors.clarityOfPresentation = 'Please rate Clarity of Presentation';
    }

    if (!feedback.engagement) {
      errors.engagement = 'Please rate Engagement';
    }

    if (!feedback.pace) {
      errors.pace = 'Please rate Pace';
    }

    if (!feedback.useOfExamples) {
      errors.useOfExamples = 'Please rate Use of Examples';
    }

    if (!feedback.knowledgeTransfer) {
      errors.knowledgeTransfer = 'Please rate Knowledge Transfer';
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFeedback(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Feedback submitted:', feedback);

    // Push the feedback to Firebase Realtime Database
    const feedbackRef = ref(database, 'course-feedbacks');
    push(feedbackRef, feedback)
      .then(() => {
        alert('Feedback submitted successfully!');
        // Reset the form
        setFeedback({
          fullName: '',
          contactNumber: '',
          emailAddress: '',
          interaction: '',
          contentRelevance: '',
          clarityOfPresentation: '',
          engagement: '',
          pace: '',
          useOfExamples: '',
          knowledgeTransfer: '',
          additionalComments: ''
        });
      })
      .catch((error) => {
        console.error('Error submitting feedback:', error);
      });
  };
  return (
    <div className="feedback-form-container">
    <h1>PDEC FEEDBACK FORM</h1>
    <h2>Synergyy Technologies - ST/PDEC/2407</h2>

    <form onSubmit={handleSubmit} className="feedback-form">
      <div className="form-group">
        <label>Full Name</label>
        <input 
          type="text" 
          name="fullName" 
          value={feedback.fullName} 
          onChange={handleChange} 
          required 
        />
        {errors.fullName && <span className="error">{errors.fullName}</span>}

      </div>

      <div className="form-group">
        <label>Contact Number</label>
        <input 
          type="text" 
          name="contactNumber" 
          value={feedback.contactNumber} 
          onChange={handleChange} 
          required 
        />
                  {errors.contactNumber && <span className="error">{errors.contactNumber}</span>}

      </div>

      <div className="form-group">
        <label>Email Address</label>
        <input 
          type="email" 
          name="emailAddress" 
          value={feedback.emailAddress} 
          onChange={handleChange} 
          required 
        />
                  {errors.emailAddress && <span className="error">{errors.emailAddress}</span>}

      </div>

      <div className="form-group">
        <label>Interaction: Were there opportunities for interaction and questions during the lecture?</label>
        <select 
          name="interaction" 
          value={feedback.interaction} 
          onChange={handleChange} 
          required
        >
          <option value="">Select an option</option>
          <option value="Excellent">Excellent</option>
          <option value="Very Good">Very Good</option>
          <option value="Good">Good</option>
          <option value="Average">Average</option>
          <option value="Poor">Poor</option>
          <option value="Very Poor">Very Poor</option>
        </select>
        {errors.interaction && <span className="error">{errors.interaction}</span>}

      </div>

      <div className="form-group">
        <label>Content Relevance: Did the content of the lecture meet your expectations?</label>
        <select 
          name="contentRelevance" 
          value={feedback.contentRelevance} 
          onChange={handleChange} 
          required
        >
          <option value="">Select an option</option>
          <option value="Excellent">Excellent</option>
          <option value="Very Good">Very Good</option>
          <option value="Good">Good</option>
          <option value="Average">Average</option>
          <option value="Poor">Poor</option>
          <option value="Very Poor">Very Poor</option>
        </select>
        {errors.contentRelevance && <span className="error">{errors.contentRelevance}</span>}

      </div>

      <div className="form-group">
        <label>Clarity of Presentation: Were the concepts presented in a clear and understandable manner?</label>
        <select 
          name="clarityOfPresentation" 
          value={feedback.clarityOfPresentation} 
          onChange={handleChange} 
          required
        >
          <option value="">Select an option</option>
          <option value="Excellent">Excellent</option>
          <option value="Very Good">Very Good</option>
          <option value="Good">Good</option>
          <option value="Average">Average</option>
          <option value="Poor">Poor</option>
          <option value="Very Poor">Very Poor</option>
        </select>
        {errors.clarityOfPresentation && <span className="error">{errors.clarityOfPresentation}</span>}

      </div>

      <div className="form-group">
        <label>Engagement: Did the lecturer engage the audience effectively throughout the lecture?</label>
        <select 
          name="engagement" 
          value={feedback.engagement} 
          onChange={handleChange} 
          required
        >
          <option value="">Select an option</option>
          <option value="Excellent">Excellent</option>
          <option value="Very Good">Very Good</option>
          <option value="Good">Good</option>
          <option value="Average">Average</option>
          <option value="Poor">Poor</option>
          <option value="Very Poor">Very Poor</option>
        </select>
        {errors.engagement && <span className="error">{errors.engagement}</span>}

      </div>

      <div className="form-group">
        <label>Pace: Was the pace of the lecture appropriate?</label>
        <select 
          name="pace" 
          value={feedback.pace} 
          onChange={handleChange} 
          required
        >
          <option value="">Select an option</option>
          <option value="Excellent">Excellent</option>
          <option value="Very Good">Very Good</option>
          <option value="Good">Good</option>
          <option value="Average">Average</option>
          <option value="Poor">Poor</option>
          <option value="Very Poor">Very Poor</option>
        </select>
        {errors.pace && <span className="error">{errors.pace}</span>}

      </div>

      <div className="form-group">
        <label>Use of Examples: Were the examples provided relevant and helpful in understanding the concepts?</label>
        <select 
          name="useOfExamples" 
          value={feedback.useOfExamples} 
          onChange={handleChange} 
          required
        >
          <option value="">Select an option</option>
          <option value="Excellent">Excellent</option>
          <option value="Very Good">Very Good</option>
          <option value="Good">Good</option>
          <option value="Average">Average</option>
          <option value="Poor">Poor</option>
          <option value="Very Poor">Very Poor</option>
        </select>
        {errors.useOfExamples && <span className="error">{errors.useOfExamples}</span>}

      </div>

      <div className="form-group">
        <label>Knowledge Transfer: Do you feel that you gained new knowledge or insights from the lecture?</label>
        <select 
          name="knowledgeTransfer" 
          value={feedback.knowledgeTransfer} 
          onChange={handleChange} 
          required
        >
          <option value="">Select an option</option>
          <option value="Excellent">Excellent</option>
          <option value="Very Good">Very Good</option>
          <option value="Good">Good</option>
          <option value="Average">Average</option>
          <option value="Poor">Poor</option>
          <option value="Very Poor">Very Poor</option>
        </select>
        {errors.knowledgeTransfer && <span className="error">{errors.knowledgeTransfer}</span>}

      </div>

      <div className="form-group">
        <label>Additional Comments: Is there anything else you would like to share about your experience with the lecture?</label>
        <textarea 
          name="additionalComments" 
          value={feedback.additionalComments} 
          onChange={handleChange} 
        ></textarea>
      </div>

      <button type="submit">Submit Feedback</button>
    </form>
  </div>
  );
};

export default SynergyyStudentFeedbackForm;
