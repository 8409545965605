import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faThLarge, faLaptop, faPlay } from '@fortawesome/free-solid-svg-icons';
import './BottomNav.css';

const BottomNav = () => {
  return (
    <div className="bottom-nav">
      <Link to="/" className="nav-item">
        <FontAwesomeIcon icon={faHome} />
        <span>Home</span>
      </Link>
      <Link to="/courses" className="nav-item">
        <FontAwesomeIcon icon={faThLarge} />
        <span>Courses</span>
      </Link>
      <Link to="/my-courses" className="nav-item">
        <FontAwesomeIcon icon={faLaptop} />
        <span>My Courses</span>
      </Link>
      <Link to="/webinars" className="nav-item">
        <FontAwesomeIcon icon={faPlay} />
        <span>Webinars</span>
      </Link>
    </div>
  );
};

export default BottomNav;
