import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import './Navbar.css';
import logo from './synergyy.png'; // Path to your logo

import { auth } from '../firebase'; // Adjust the path to your firebase.js file

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [user, setUser] = useState(null);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    document.body.classList.toggle('menu-open', !menuOpen);
  };
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleLogout = () => {
    auth.signOut();
  };

  return (
    <>
      <nav className="navbar">
        <div className="navbar-logo">
{/*           <span className="logo-text">SynergyyTech</span>*/}          
              {/* <img src={logo} alt="SynergyyTech Logo" className="logo-image" /> */}
              <Link to="/" className="logo-link"> {/* Wrap the img with Link */}<img src={logo} alt="SynergyyTech Logo" className="logo-image" /></Link>
{/*           <span className="logo-subtext">ENGAGE | INSPIRE | THRIVE</span>
 */}        </div>
        <div className="navbar-menu" onClick={toggleMenu}>
          <FontAwesomeIcon icon={faBars} />
        </div>
      </nav>
      <div className={`sidebar ${menuOpen ? 'open' : ''}`}>
        <Link to="/" className="sidebar-item" onClick={toggleMenu}>Home</Link>
        <Link to="/courses" className="sidebar-item" onClick={toggleMenu}>Courses</Link>
        <Link to="/my-courses" className="sidebar-item" onClick={toggleMenu}>My Courses</Link>
        <Link to="/synergyy-jobpostings" className="sidebar-item" onClick={toggleMenu}>Job Postings</Link>
        <Link to="/synergyy-community" className="sidebar-item" onClick={toggleMenu}>Community</Link>
        <Link to="/about" className="sidebar-item" onClick={toggleMenu}>About</Link>
        <Link to="/contact" className="sidebar-item" onClick={toggleMenu}>Contact Us</Link>
        <Link to="/terms" className="sidebar-item" onClick={toggleMenu}>Terms and Conditions</Link>
{/*         <Link to="/SignInPage" className="sidebar-item" onClick={toggleMenu}>SignIn</Link>
        <Link to="/register" className="sidebar-item" onClick={toggleMenu}>Register</Link> */}
        {user ? (
          <>
            <Link to="/Account" className="sidebar-item" onClick={toggleMenu}>My Account</Link>
            <Link to="/Logout" className="sidebar-item" onClick={handleLogout}>Logout</Link>
          </>
        ) : (
          <>
           <Link to="/SignInPage" className="sidebar-item" onClick={toggleMenu}>SignIn</Link>
           <Link to="/register" className="sidebar-item" onClick={toggleMenu}>Register</Link>
          </>
        )}
      </div>
    </>
  );
};

export default Navbar;