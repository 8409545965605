import React, { useState } from 'react';
import './coursepg.css';
import { Link } from 'react-router-dom';
/* import plusCourseLogoBg from './PlusCourseBg.png';
 */import pdeCourseLogoBg from './PDECourseBg.png';
import Testimonials from './Testimonials';
import pdeCourseContent from './ST_ASPEN-PLUS_Single-Page.png';
import { database } from '../firebase'; // Import the Firebase database
import { ref, set } from 'firebase/database'; // Import Firebase database functions

const AspenPlus = () => {

  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    education: 'B.Tech',
  });
  const [errors, setErrors] = useState({
    name: '',
    phone: '',
    email: '',
  });

  const validate = () => {
    let formValid = true;
    let errors = {};

    if (!formData.name) {
      formValid = false;
      errors.name = 'Name is required';
    }

    const phonePattern = /^[0-9]{10}$/;
    if (!formData.phone || !phonePattern.test(formData.phone)) {
      formValid = false;
      errors.phone = 'Valid phone number is required';
    }

    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!formData.email || !emailPattern.test(formData.email)) {
      formValid = false;
      errors.email = 'Valid email is required';
    }

    setErrors(errors);
    return formValid;
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validate()) {
      const newEnrollmentRef = ref(database, 'enrollments/Aspen Plus/' + Date.now());
      set(newEnrollmentRef, formData)
        .then(() => {
          alert('Enrollment successful!');
          setFormData({
            name: '',
            phone: '',
            email: '',
            education: 'B.Tech',
          });
        })
        .catch((error) => {
          console.error('Error enrolling:', error);
        });
    }
  };


  return (
    <div>
      <div className="course_title">
      <h1>Aspen Plus</h1>
      </div>

      <div className="course-content-container">
      <p>
        Aspen Plus course will improve your skills which are the most demanding in Chemical and Petrochemical Industries.
        This course focuses on understanding the important technical fundamentals of chemical process Simulation. 
      </p>

      <div className="enrollment-section">
        <div className="enrollment-form">
          <h2>Enroll for Aspen Plus</h2>
          <form onSubmit={handleSubmit}>
              <label>Name (Required)</label>
              <input type="text" name="name" value={formData.name} onChange={handleChange} required />
              {errors.name && <span className="error">{errors.name}</span>}

              <label>Phone (Required)</label>
              <input type="text" name="phone" value={formData.phone} onChange={handleChange} required />
              {errors.phone && <span className="error">{errors.phone}</span>}

              <label>Email (Required)</label>
              <input type="email" name="email" value={formData.email} onChange={handleChange} required /> 
              {errors.email && <span className="error">{errors.email}</span>}
              
              <label>Highest Education (Required)</label>
              <select name="education" value={formData.education} onChange={handleChange} required>
                <option value="B.Tech">B.Tech</option>
                <option value="M.Tech">M.Tech</option>
                <option value="PhD">PhD</option>
              </select>
              
              <button type="submit">Enroll Now</button>
            </form>
        </div>
        <div className="image2-container">
          <img src={pdeCourseContent} alt="Learn Aspen Plus" />
        </div>
      </div>

      <div className="content-section">
          <h2>Course Content</h2>
          <ul>
          <ul>
            <li>Introduction to Aspen Plus</li>
            <li>Property Environment - Selection of Physical Property Methods</li>
            <li>Simulation Environment</li>
            <li>Understanding Piping Network</li>
            <li>Understanding of Unit Operation Models in Aspen Plus</li>
            <li>Flow sheeting Options</li>
            <li>Model Analysis Tools</li>
            <li>Equipment Sizing</li>
            <li>Other Operations: Batch, Gas absorption, LLE</li>
            <li>Advance Operations: Solid Processing, Capital Cost estimation</li>
            <li>Industrial Case Studies</li>
            <li>Aspen Certification Exam Guidance</li>
          </ul>
          </ul>
        </div>

        <div className="content-section">
          <h2>What will you learn?</h2>
          <ul>
          <li>Identify the benefits of process simulation using Aspen Plus</li>
            <li>Describe the capabilities of Aspen Plus</li>
            <li>Familiarize yourself with Aspen Plus graphical user interface and organizational structure</li>
            <li>Learn the basic concepts necessary for creating simulations in Aspen Plus</li>
            <li>Enter necessary elements to fully define a Fluid Package</li>
            <li>Select the appropriate property method for your application</li>
            <li>Define material streams and connect unit operations to build a flowsheet</li>
            <li>Modify and set desired units of measure</li>
            <li>Review stream analysis options</li>
            <li>Add and connect unit operations to build a flowsheet</li>
            <li>Use the Report Manager to create custom unit operation and stream reports</li>
            <li>Use Aspen Plus to perform property analysis of pure components and mixtures</li>
            <li>Use Aspen Plus in thermodynamics instruction for Vapor-Liquid, Liquid-Liquid and Vapor-Liquid-Liquid Equilibrium calculations</li>
            <li>Build, navigate and optimize steady state simulation models using Aspen Plus</li>
            <li>Utilize a wide variety of unit operation models and calculation tools to model process equipment</li>
            <li>Evaluate the performance of existing equipment by leveraging the equipment rating capabilities of Aspen Plus</li>
            <li>Perform Case Studies to determine the optimum operating points for a process</li>
            <li>Design, revamp and debottleneck process equipment</li>
            <li>Use the Model Analysis Tools to run sensitivity analysis and optimize your process</li>
            <li>Calculate process performance and thermophysical data with user subroutines in Fortran</li>
            <li>Investigate reasons why a simulation may produce poor results or errors</li>
            <li>Use suggested tips to debug a variety of simulations</li>
            <li>Understand best practices and learn how to troubleshoot simulations</li>
            <li>Identify and explain the various classes of distillation and separations models available in Aspen Plus</li>
            <li>Gain the skills and knowledge to model distillation, separation and extraction processes</li>
            <li>Reduce process design time by using advanced features of RadFrac distillation columns</li>
            <li>Use column analysis tools to optimize the feed location and number of stages and improve energy utilization for distillation columns</li>
            <li>Add and manipulate column specifications to meet process objectives</li>
            <li>Construct, run, manipulate and analyze a distillation column</li>
            <li>Specify required parameters in order to execute flash calculations and fully define material streams</li>
            <li>Identify and explain the various classes of reactor models available in Aspen Plus (PFR, CSTR…)</li>
            <li>Model Plug Flow, Continuous Stirred Tank and Fluidized Bed Reactors</li>
            <li>Enter reaction stoichiometry and kinetic data for simple (POWERLAW) and complex (LHHW) reaction types</li>
            <li>Use the Model Analysis Tools to run sensitivity analysis and optimize the operating conditions of a chemical reactor</li>
            <li>Use the Model Analysis Tools to run sensitivity analysis and optimize the selectivity of a given chemical reaction</li>
            <li>Identify and explain the various classes of piping system models available in Aspen Plus (pipes, valves, pumps, compressors)</li>
            <li>Model piping components (pipes, fittings, valves)</li>
            <li>Model fluid movers (pumps, compressors)</li>
            <li>Model piping systems</li>
            <li>Mitigate the risk for cavitation or choked flow using Aspen Plus</li>
            <li>Learn how to economically optimize your piping system</li>
            <li>Compare and contrast the applicability and operation of different heat exchanger models available in Aspen Plus</li>
            <li>Learn the fundamentals of producing an optimized shell & tube heat exchanger design</li>
            <li>Implement Aspen Exchanger Design & Rating (EDR) for rigorous heat exchanger calculations within Aspen Plus</li>
            <li>Use the Activated Exchanger Analysis feature for continuous heat exchanger study and design</li>
            <li>Design and rate a shell and tube heat exchanger using the EDR interface inside Aspen Plus</li>
            <li>Identify and explain the various classes of solids and solids separator models available in Aspen Plus</li>
            <li>Gain the practical skills and knowledge to begin modeling new and existing solids processes (crushers, fluidized beds, dryers, crystallizers…)</li>
            <li>Learn practical techniques for building and troubleshooting solids models</li>
          </ul>
        </div>
    </div>

      <div className="course-container">
      <h1>Explore Training Programs</h1>
      <h2>Boost your career with industrial training programs</h2>
      <div className="courses">
        <div className="course">
          <Link to="/PDE">
          <div className="image-container">
            <img src={pdeCourseLogoBg} alt="Process Design & Engineering" />
            <div className="overlay">
            <h3>Process Design & Engineering</h3>
            </div>
            </div>
          </Link>
        </div>
       {/*  <div className="course">
          <Link to="/AspenPlus">
          <div className="image-container">
            <img src={plusCourseLogoBg} alt="Aspen Plus" />
            <div className="overlay">
            <h3>Aspen Plus</h3>
            </div>
            </div>
          </Link>
        </div> */}
      </div>
      </div>
      <Testimonials />

    </div>
  );
};

export default AspenPlus;
