// ProcessSafety.js
import React from 'react';
import '../MyCourses.css';

const PaymentSuccess = () => {
  return (
    <div className="my-courses">
      <h1>Payment Successful</h1>
      <p>Thank you for your payment! Your course will be added to your account within 2 hours.</p>
      <p>If you have any questions, contact us at +91-9429992192.</p>
    </div>
  );
};

export default PaymentSuccess;

