import React from 'react';
import './Privacy.css';

const Privacy = () => {
  return (
    <div className="privacy-container">
      <h1>Privacy Terms</h1>
      <p>Synergyy Tech Pvt Ltd collects, uses and is responsible for certain personal information provided by our site visitors which is processed in accordance with this statement. Please contact us using the details below if you have any questions on these terms or the service that we provide.</p>
      
      <h2>What information we hold about you?</h2>
      <p>We collect personal information whenever you provide it to us. This personal information may include the following:</p>
      <ul>
        <li>Name, email and other contact details.</li>
        <li>Your academic history including qualifications.</li>
        <li>Your professional or employment details.</li>
      </ul>

      <h2>How we use your information?</h2>
      <p>We use your information for the following purposes:</p>
      <ul>
        <li>Responding to any enquiries which you make to us related to any of our professional training course.</li>
        <li>To process and administer your payments;</li>
        <li>To provide training services to you;</li>
        <li>To provide you with promotional information about our training services, news, advice, and offers.</li>
      </ul>

      <h2>Who we may share your information with?</h2>
      <p>We may disclose your personal information to:</p>
      <ul>
        <li>Our franchise partners.</li>
        <li>Our agents and service providers.</li>
        <li>our faculty & training staff.</li>
        <li>Third party placement consultants.</li>
        <li>Recruiting Companies.</li>
      </ul>

      <h2>Keeping your information secure</h2>
      <p>We will use proper technical and organizational measures to safeguard your personal data. We store your personal data on secure servers provided by cloud CRM service providers and ensure access to your personal data is limited to authenticated and approved Synergyy staff. While we will use all reasonable efforts to safeguard your personal data, you acknowledge that the use of the internet is not entirely secure and for this reason we cannot guarantee the security or integrity of any personal data that are transferred from you or to you via the internet.</p>

      <h2>Reasons we collect and use your personal information</h2>
      <ul>
        <li>Responding to enquiries.</li>
        <li>Providing training to our participants.</li>
        <li>Improving and developing our services by taking continuous feedback.</li>
        <li>The administration of our business.</li>
        <li>Monitoring and maintaining standards within the Pitman Training network.</li>
      </ul>

      <h2>Retention of Information</h2>
      <p>We will retain your information for no longer than is necessary for the purposes for which we collected it, or for as long as we have your consent to do so where your consent is our legal basis on which we process such data. All retained data will be held subject to this policy.​</p>
    </div>
  );
}

export default Privacy;
