import React, { useState, useEffect } from 'react';
import './MyCourses.css';
import { auth } from '../firebase'; // Adjust the path to your firebase.js file
import { Link } from 'react-router-dom';

const SynergyyChemConnect = () => {
  const [user, setUser] = useState(null);
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user); // User is logged in
      } else {
        setUser(null); // User is logged out
      }
    });
  
    return () => unsubscribe(); // Clean up the listener on component unmount
  }, []);
  return (
    <div className="my-courses">
    <h1 id="chem-connect">Synergyy Chem-Connect Monthly</h1>
      
    {user ? (<><p>Stay updated with the latest trends, research, and developments in the chemical industry.</p></>) : (<><span>Login is Required:  </span> <Link to="/SignInPage">SignIn</Link><span> or </span> <Link to="/register">Register</Link> </>)}     
    {/* Add my courses content here */}
  </div>
  );
};

export default SynergyyChemConnect;
