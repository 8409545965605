import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyCThM30lHz31Mp1eLkXTfu4e0DGvoCsDXw",
    authDomain: "synergyytech.firebaseapp.com",
    databaseURL: "https://synergyytech-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "synergyytech",
    storageBucket: "synergyytech.appspot.com",
    messagingSenderId: "358018414843",  
    appId: "1:358018414843:web:4163e083eece374164d85e",
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const auth = getAuth(app);

export { database,auth };


// firebase.js
/* import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
  apiKey: "AIzaSyCLJgDjhea-S7djzQgh-Q8Cn1NC187ed94",
  authDomain: "lets-do-now.firebaseapp.com",
  databaseURL: "https://lets-do-now-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "lets-do-now",
  storageBucket: "lets-do-now.appspot.com",
  messagingSenderId: "852658827155",
  appId: "1:852658827155:web:edb0723260e0d52d044266"
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

export { database };
 */