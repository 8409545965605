import React from 'react';
import './Terms.css';

const Terms = () => {
  return (
    <div className="terms-container">
      <h1>Terms & Conditions</h1>
      <p>These are the terms and conditions on which we supply and deliver training courses to you, whether these are online training or classroom session. Below terms in details:</p>
      
      <h2>Registration & Enrollment:</h2>
      <ul>
        <li>By enrolling course provided by Synergyy Tech, you agree to be legally bound by the following terms and conditions (these “Terms and conditions of training & enrollment”).</li>
        <li>Participant training will commence only after receipt of 100% program fees mentioned in our course invitation letter.</li>
        <li>By registering for a training course with us, you verify that the details you have actually provided us with your enrollment are complete, accurate as well as appropriate at the time it is provided.</li>
        <li>By registering for an online training course with us, you verify that you have accessibility to an appropriate workplace, functional computer, microphone headset or telephone, and good internet connection to enjoy a fully interactive learning experience through Synergyy Tech.</li>
        <li>Student enroll for training have their sole purpose of learning.</li>
      </ul>

      <h2>Training:</h2>
      <ul>
        <li>All our training programs comes with well defined duration in hours. The total hours mentioned in our course syllabus includes actual learning hours along with practice sessions & project work.</li>
        <li>The course duration mentioned on our syllabus are calculated on the basis of average time required to deliver the content related to specific topic, but this may vary depending on the understanding of participants.</li>
        <li>Average session duration for our weekdays online training is 1 hrs.</li>
        <li>Average session duration for our weekend online training is 2 hrs.</li>
        <li>Average session duration for our online virtual training is 2 hrs. & this may vary in order to maintain the effectiveness of training program.</li>
        <li>If our training program is delayed or postponed by an event outside our control then we or our faculty will contact you as soon as possible to let you know and we will take steps to minimize the effect of the delay. Provided we do this we will not be liable for delays caused by the event.</li>
        <li>All our training program are delivered by experienced & working faculties, this may result in last moment change in training schedule due to faculty’s commitment with his/her employer, if any such incident occurs the same will be communicated to our participants as early as possible & their lecture will reschedule.</li>
        <li>If participant faces any issue related to quality of training delivered, the same to be communicated to us by email. Participant can report us such issue by writing us at support@Synergyy.in.</li>
      </ul>

      <h2>Communication:</h2>
      <ul>
        <li>For ease of communication all participant will provided with their faculty’s contact number.</li>
        <li>Participant’s are not allowed to share any of our faculty’s number with any third person.</li>
        <li>For any technical query, students can contact faculty during training sessions or after training sessions.</li>
        <li>Apart from training hours if students come across any issue while referring training manual, he or she supposed to mail the issue to faculty or may use instant messaging service like Whatsapp for a quick response.</li>
        <li>Please avoid frequent calling to faculty, as all our faculty members are working professionals & students frequent calls may disturb their office activities. Kindly prefer Whatsapp message over a call.</li>
        <li>Apart from the technical discussion, the candidate may contact their program coordinator to resolve their queries.</li>
      </ul>

      <h2>Learning Material & Software’s:</h2>
      <ul>
        <li>All course material will be shared with the participant at the end of every session in a phased manner.</li>
        <li>Training notes & write up will be provided in softcopy.</li>
        <li>In case if any material is not shared, students may get it from their program coordinator.</li>
        <li>Software related to the technical module will be installed before the start of that particular session. The request for the same will be generated by faculty to the program coordinator.</li>
      </ul>

      <h2>Program Schedule:</h2>
      <ul>
        <li>Timing for weekend sessions are flexible & can finalized with faculty & students consent.</li>
        <li>Training schedule may subject to change, in case faculty is not available because of his or her office commitment. This inconsistency will be taken care on weekend sessions.</li>
      </ul>

      <h2>Privacy & Non-Disclosure Clause:</h2>
      <ul>
        <li>Candidates are not allowed to reveal faculty identity related to his job or employer on any professional or social networking sites or in general discussion with any external members.</li>
        <li>Commercial terms & program fees agreed between Synergyy Tech & participants shall not be discussed or disclosed to faculty.</li>
      </ul>

      <h2>Admission Termination & cancellation term & refund policy:</h2>
      <ul>
        <li>No admission cancellation is applicable once the enrollment is done on website or through direct banking.</li>
        <li>No refund shall be provided if student enroll using combo & other offer.</li>
        <li>If we found that any of our participant not following terms & conditions mentioned above, for such participant admission will be terminated on immediate basis without any refund.</li>
      </ul>

      <h2>Certification Support:</h2>
      <ul>
        <li>Certification will be issued within 10 – 15 days of course completion.</li>
      </ul>
    </div>
  );
}

export default Terms;
